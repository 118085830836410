<template>
  <div class="fixed top-0 right-0 bottom-0 left-0 z-modal">
    <Overlay
      :overlay-active="overlayActive"
      class="z-overlay"
      @close-overlay="$emit('close-market-modal')"
    >
    </Overlay>
    <div
      class="flex flex-col h-full w-full relative md:p-64 p-0 md:items-end md:justify-end md:w-auto justify-start z-modal"
      @click.self="$emit('close-market-modal')"
    >
      <transition name="slide" mode="out-in">
        <div
          v-show="showContent"
          class="relative md:max-w-460 w-full mx-auto bg-white"
        >
          <div
            class="absolute top-24 right-24 w-24 h-24 cursor-pointer select-none flex justify-center items-center"
            @click="$emit('close-market-modal')"
          >
            <ClientOnly>
              <fa
                class="text-lg cursor-pointer select-none"
                :icon="['fal', 'times']"
              ></fa>
            </ClientOnly>
          </div>
          <div v-if="selectedMarket" class="p-24">
            <div class="mb-16 base-medium-text">
              {{ $lang('sharedResources', 'changeCountryHeading') }}
            </div>
            <div class="mb-24 relative">
              <div
                class="px-16 py-20 border border-grey600 rounded flex justify-between items-center bg-grey200 select-none cursor-pointer"
                :class="{
                  'rounded-b-none': marketSelectorOpen,
                }"
                @click="openMarketSelector"
              >
                <div class="flex items-center base-text">
                  <img v-if="selectedMarket.countryCode === 'SE'" src="~/assets/icons/flag-defs-se.svg" class="h-20 w-20 mr-8 object-contain" />
                  <img v-else-if="selectedMarket.countryCode === 'DK'" src="~/assets/icons/flag-defs-dk.svg" class="h-20 w-20 mr-8 object-contain" />
                  <img v-else-if="selectedMarket.countryCode === 'AT'" src="~/assets/icons/flag-defs-at.svg" class="h-20 w-20 mr-8 object-contain" />
                  <img v-else-if="selectedMarket.countryCode === 'SK'" src="~/assets/icons/flag-defs-sk.svg" class="h-20 w-20 mr-8 object-contain" />
                  <img v-else src="~/assets/icons/flag-defs-global.svg" class="h-20 w-20 mr-8 object-contain" />
                  {{ selectedMarket.country }}
                </div>
                <ClientOnly><fa :icon="['far', 'chevron-down']"></fa></ClientOnly>
              </div>
              <transition name="market-appear" mode="out-in">
                <div
                  v-show="marketSelectorOpen"
                  class="absolute right-0 left-0 md:max-h-160 max-h-460 bg-white z-10 rounded-b border-b border-r border-l border-grey600 overflow-auto cursor-pointer select-none"
                >
                  <a
                    v-for="market in markets"
                    :key="market.countryId"
                    :href="market.url"
                    class="flex items-center p-16 cursor-pointer select-none"
                    @click.prevent="onMarketSelect(market)"
                  >
                    <img v-if="market.countryCode === 'SE'" src="~/assets/icons/flag-defs-se.svg" class="h-20 w-20 mr-8 object-contain" />
                    <img v-else-if="market.countryCode === 'DK'" src="~/assets/icons/flag-defs-dk.svg" class="h-20 w-20 mr-8 object-contain" />
                    <img v-else-if="market.countryCode === 'AT'" src="~/assets/icons/flag-defs-at.svg" class="h-20 w-20 mr-8 object-contain" />
                    <img v-else-if="market.countryCode === 'SK'" src="~/assets/icons/flag-defs-sk.svg" class="h-20 w-20 mr-8 object-contain" />
                    <img v-else src="~/assets/icons/flag-defs-global.svg" class="h-20 w-20 mr-8 object-contain" />
                    {{ market.country }}
                  </a>
                </div>
              </transition>
            </div>
            <Button
              :size="Sizes.lg"
              :theme="Themes.dark"
              :text="selectedMarket.changeCountryLabel"
              :is-block="true"
              @click="onMarketApply"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { Sizes, Themes } from '../constants/button-layouts';
import Button from './globals/Button.vue';
import Overlay from './body/Overlay.vue';
import { type IMarket } from '~/models/api-types';

const config = useGlobalContentStore().config;

const { $lang } = useNuxtApp();

const props = defineProps<{
  isOpen?: boolean,
}>();

const selectedMarket = ref(config?.currentMarket);
const marketSelectorOpen = ref(false);
const overlayActive = ref(false);
const showContent = ref(false);

const markets = computed(() => {
  return config.markets;
});

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) {
      setTimeout(() => (overlayActive.value = true), 75);
      setTimeout(() => (showContent.value = true), 100);
      document.body.classList.add('locked');
    } else {
      document.body.classList.remove('locked');
      overlayActive.value = false;
      showContent.value = false;
    }
  }
);

const openMarketSelector = () => {
  marketSelectorOpen.value = !marketSelectorOpen.value;
};

const onMarketSelect = (market: IMarket) => {
  selectedMarket.value = market;
  marketSelectorOpen.value = false;
};

const onMarketApply = () => {
  const ageGateCookie = useCookie('age_gate_market', {
    path: '/',
    maxAge: 60 * 60 * 24 * 180,
  });

  ageGateCookie.value = selectedMarket.value?.url || '/';
  window.location.href = selectedMarket.value?.url || '/';
  emit('close-market-modal');
};

const emit = defineEmits<{
  (event: 'close-market-modal'): void,
}>();
</script>
<style scoped>
.market-appear-enter-active {
  transition: all 0.3s ease;
}
.market-appear-leave-active {
  transition: all 0.3s ease;
}

.market-appear-enter,
.market-appear-leave-to {
  max-height: 0;
}

.market-appear-enter-to,
.market-appear-leave {
  max-height: 460px;

  @screen md {
    max-height: 160px;
  }
}

.slide-enter-active {
  transition: all 0.2s ease;
}
.slide-leave-active {
  transition: all 0.2s;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-50px);
  @screen md {
    transform: translateY(50px);
  }
}
</style>
